window.menuHandler = () => {
  function mobileMenuAnimation() {
    //OPEN TRIGGER
    const openTrigger = document.getElementsByClassName('menu-trigger');

    if (openTrigger[0]) {
      const openTriggerTop = document.getElementsByClassName('menu-trigger-bar top');
      const openTriggerMiddle = document.getElementsByClassName('menu-trigger-bar middle');
      const openTriggerBottom = document.getElementsByClassName('menu-trigger-bar bottom');

      //CLOSE TRIGGER
      const closeTrigger = document.getElementsByClassName('close-trigger');
      const closeTriggerLeft = document.getElementsByClassName('close-trigger-bar left');
      const closeTriggerRight = document.getElementsByClassName('close-trigger-bar right');

      //LOGO
      const logo = document.getElementsByClassName('navbar__href__logo');

      //MENU
      const menuContainer = document.getElementsByClassName('inner-container');
      const navbar = document.getElementsByClassName('navbar');
      const menu = document.getElementsByClassName('menu');
      const menuTop = document.getElementsByClassName('menu-bg top');
      const menuMiddle = document.getElementsByClassName('menu-bg middle');
      const menuBottom = document.getElementsByClassName('menu-bg bottom');

      const body = document.body;

      //EVENTS
      openTrigger[0].addEventListener('click', () => {
        body.classList.add('overflow-hidden');
        openTrigger[0].classList.add('hidden');
        menuContainer[0].classList.add('show-menu');
        closeTriggerLeft[0].classList.add('show-rotate-left');
        closeTriggerRight[0].classList.add('show-rotate-right');
        menu[0].classList.add('show');
        navbar[0].classList.add('top-null');
      });

      closeTrigger[0].addEventListener('click', () => {
        body.classList.remove('overflow-hidden');
        openTrigger[0].classList.remove('hidden');
        menuContainer[0].classList.remove('show-menu');
        closeTriggerLeft[0].classList.remove('show-rotate-left');
        closeTriggerRight[0].classList.remove('show-rotate-right');
        menu[0].classList.remove('show');
        navbar[0].classList.remove('top-null');
      });
    }
  }

  const navbarScroll = () => {
    let prevScrollpos = window.scrollY;
    if (document.getElementById('navbar')) {
      window.onscroll = function () {
        let currentScrollPos = window.scrollY;
        if (prevScrollpos > currentScrollPos || currentScrollPos <= 0) {
          document.getElementById('navbar').style.top = '0';
          if (currentScrollPos > 0) {
            document.getElementById('navbar').style.backgroundColor = '#0b0a0e';
          } else {
            document.getElementById('navbar').style.backgroundColor = '';
          }
        } else {
          document.getElementById('navbar').style.top = '-105px';
        }
        prevScrollpos = currentScrollPos;
      };
    }
  };

  if (document.readyState === 'complete' || (document.readyState !== 'loading' && !document.documentElement.doScroll)) {
    mobileMenuAnimation();
    navbarScroll();
  } else {
    document.addEventListener('DOMContentLoaded', () => {
      mobileMenuAnimation();
      navbarScroll();
    });
  }
  window.addEventListener('resize', mobileMenuAnimation);
};
